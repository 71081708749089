<template>
  <div>
    <el-card>
      <el-page-header :content="title" style="margin-bottom: 30px;" @back="goBack" />
      <nav class="input" style="width: auto">
        <el-select v-model="search.isSend" placeholder="状态" size="medium">
          <el-option label="全部" :value="-1" />
          <el-option label="未发货" :value="0" />
          <el-option label="已发货" :value="1" />
        </el-select>
      </nav>
      <nav class="input" style="width: 150px">
        <el-button size="small" @click="reset">重置</el-button>
        <el-button type="primary" size="small" @click="subimt">{{
            '搜索'
          }}</el-button>
      </nav>
    </el-card>

    <el-card style="margin-top: 10px">
      <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
        <el-table-column prop="id" width="60" label="编号" />
        <el-table-column prop="productName" label="商品名称" v-if="!$route.query.id" />
        <el-table-column prop="userName" label="姓名" />
        <el-table-column prop="phone" label="会员电话" />
        <el-table-column :prop="type?'code':'expressNo'" :label="type?'虚拟卡密':'快递单号'" />
        <el-table-column prop="isSend" label="状态">
          <template slot-scope="{row}">
            <el-tag type="warning">
              {{row.isSend*1?'已发货':"未发货"}}
            </el-tag>
          </template>

        </el-table-column>
        <el-table-column prop="createTime" label="兑换时间">
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button :disabled="!!row.isSend" type="text" icon="el-icon-s-promotion" size="mini"
              style="color:skyblue" @click="shelves(row)">{{row.isSend*1?'已发货':"发货"}}</el-button>
              <el-button v-if="!type" type="text" icon="el-icon-camera" size="mini" class="green" @click="seeTheLink(row)">查看发货地址</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :current-page="search.page" background :page-sizes="[3, 5, 10, 15]"
        :page-size="search.pageSize" layout="total, prev, pager, next" :total="total"
        @current-change="handleCurrentChange" />
    </el-card>
    <popup ref="pop" title="输入快递单号" :id="6" @getlist="getlist"></popup>
    <popup ref="pop_7" title="发货地址" :id="7" @getlist="getlist"></popup>
  </div>
  
</template>

<script>
import list from '/src/mixin/list.js'
import popup from '@/components/popup.vue';
export default {
  mixins: [list],
  components: { popup },
  data() {
    return {
      search: {
        isSend: '',
        page: 1,
        pageSize: 10
      },
      form:{}
    }
  },
  methods: {
    async getlist(val) {
    //   if (!this.$route.query.id) {
    //     return
    //   }
      const form = val || this.search

      let { data: res } = await this.$http.get('/admin/BonusMall/getOrderList', {
        params: {
          ...form,
          productId: this.$route.query.id
        }
      })
      this.title = res.data.product.name
      this.type=res.data.product.type
      this.list = res.data.list
      this.total = res.data.totalCount
      //   console.log(res);

    },
  async  seeTheLink(row){
      var {data:res} = await this.$http.get('/admin/BonusMall/getAddress',{params:{orderId:row.id}})
    //   console.log(res);
    this.$refs.pop_7.form=res.data
    this.$refs.pop_7.dialogVisible=true
    },
    handleCurrentChange(e){
            this.search.page = e
            this.form.page=e
            this.getlist(this.form)
    },
   async shelves(row){
       this.$refs.pop.dialogVisible=true
       this.$refs.pop.form={
           ...row
       }
        //  const {
        //         data: res
        //     } = await this.$http.post('/admin/BonusMall/orderSend', {
        //         id: row.id,
        //         expressNo:row.expressNo
        //     })
        //     if (res.errorCode == 200) {
        //         this.$message.success(res.message)
        //         this.getlist()
        //     }
    },
    subimt() {
        this.form={...this.search}
        this.getlist()
    }
  },
  created() {
    if(this.$route.query.isSend == 0 || this.$route.query.isSend == 1)this.search.isSend = +this.$route.query.isSend 
    this.getlist();
    this.form={...this.search}
  },
}
</script>

<style lang="less" scoped>
</style>